<template>
    <div class="invoiceDetails">
        
        <div class="contentHolder">

            <div class="companyName">
                <h3>Truck Dispatch Services Inc</h3>
            </div>
            
            <div class="row">
                <div class="name">Invoice</div>
                <div class="value">#{{ invoice.invoiceNumber }}</div>
            </div>
            <div class="row">
                <div class="name">Due date</div>
                <div class="value">{{ invoice.dueDate }}</div>
            </div>

            <div class="row" v-if="invoice.status != 'cancelled'">
                <div class="name">Subtotal</div>
                <div class="value">${{ priceConverter(invoice.total.toFixed(2)) }}</div>
            </div>

            <div class="row" v-if="invoice.status != 'cancelled'">
                <div class="name">Total</div>
                <div class="value">${{ priceConverter(invoice.total.toFixed(2)) }}</div>
            </div>

            <div class="row" v-if="invoice.status != 'cancelled' && invoice.amountPaid > 0">
                <div class="name">Amount paid</div>
                <div class="value">${{ priceConverter(invoice.amountPaid.toFixed(2)) }}</div>
            </div>

            <div class="row" v-if="invoice.status != 'cancelled'">
                <div class="name">Balance due</div>
                <div class="value">${{ priceConverter(invoice.amountDue.toFixed(2)) }}</div>
            </div>

            <div class="row">
                <div class="name">Gross</div>
                <div class="value">${{ priceConverter(grossTotal.toFixed(2)) }}</div>
            </div>

            <div class="actionBtns">
                <form :action="apiURL + '/invoice/DownloadPDF/' + invoice._id" method="GET" target="_blank">
                    <button class="default medium round">Download PDF</button>
                </form>
            </div>

        </div>


    </div>
</template>

<script>
    export default {
        props: ['invoice'],
        computed: {
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            grossTotal() {
                let total = 0;
                for(const item of this.invoice.items) {
                    total += item.quantity;
                }
                return total;
            }
        }
    }
</script>

<style lang="scss" scoped>
.invoiceDetails {
    display: grid;
    row-gap: 20px;
}

.companyName, .actionBtns {
    padding: 20px;
    display: flex;
}

.contentHolder {
    box-shadow: $boxShadow;
}

</style>