<template>
    <div class="invoicePage">
        

        <div class="invoiceGroup" v-if="unlocked">
            <div class="invoiceWrapper" v-if="invoice">
                
                <div class="companyHeaderGroupHolder">
                    <div class="companyHeaderGroup">
                        <h1>Truck Dispatch Services Inc</h1>

                        <div class="balance" v-if="invoice.status == 'pending'">
                            <div class="subtle">Balance due</div>
                            <div class="value">${{ priceConverter(invoice.amountDue.toFixed(2)) }}</div>
                            <div class="status">
                                <InvoiceStatus :item="invoice"/>
                            </div>
                        </div>

                        <div class="balance" v-else>
                            <div class="subtle">Invoice total</div>
                            <div class="value">${{ priceConverter(invoice.total.toFixed(2)) }}</div>
                            <div class="status">
                                <InvoiceStatus :item="invoice"/>
                            </div>
                        </div>


                        <div class="billTo">
                            <div class="text">Bill to</div>
                            <h2>{{ invoice.carrierDetails ? invoice.carrierDetails.name : 'Unknown' }}</h2>
                        </div>


                    </div>
                    <div class="options">
                        <button class="option" :key="key" v-for="(opt, key) of options" @click="selectedOption = key" :class="{ 'active' : selectedOption == key }">{{ opt.name }}</button>
                    </div>
                </div>

                <div class="invoiceContentHolder">
                    <component :invoice="invoice" :is="options[selectedOption].component"></component>
                </div>

                
            </div>
        </div>

        <div class="passwordWrapper" v-else-if="!bodyMessage">
            <form class="formGroup" @submit.prevent="getInvoice">
                <h2>Unlock invoice</h2>
                <Input type="password" :error="errors.password" name="Password" v-model="password" placeholder="Enter password to unlock"/>
                <button type="submit" class="default">Unlock</button>
            </form>
        </div>

        <div class="messageGroup" v-else>
            <div class="message">{{ bodyMessage }}</div>
        </div>

    </div>
</template>

<script>
import InvoiceDetails from '../../components/invoices/InvoiceDetails.vue'
import InvoiceStatus from '../../components/invoices/InvoiceStatus.vue'
import LoadDetails from '../../components/invoices/LoadDetails.vue'

    export default {
        components: {
            InvoiceDetails,
            LoadDetails,
            InvoiceStatus
        },
        props: [],
        data() {
            return {
                invoice: null,
                password: '',
                unlocked: true,
                errors: {},
                bodyMessage: null,
                options: [
                    { name: 'Loads', component: 'LoadDetails' },
                    { name: 'Details', component: 'InvoiceDetails' }
                ],
                selectedOption: 0
            }
        },
        computed: {
            id() {
                return this.$route.params.id;
            }
        },
        mounted() {
            this.getInvoice();
        },
        methods: {
            getInvoice() {
                this.$axios.post('/invoices/Details', {
                    id: this.id,
                    password: this.password
                })
                .then(data => {
                    let body = data.data;

                    if(body.requirePassword) {
                        this.unlocked = false;
                    } else if(body._id) {
                        this.invoice = body;
                        this.unlocked = true;
                    } else {
                        this.bodyMessage = "Something went wrong. Please, try again!";
                    }
                    

                })
                .catch(err => {
                    this.unlocked = false;
                    let response = err.response;

                    if(response && response.data) {
                        let body = response.data;


                        if(body.errors) {
                            this.errors = body.errors;
                        } else if(body.message) {
                            this.bodyMessage = body.message;
                        } else {
                            this.bodyMessage = "Something went wrong. Please, try again!";
                        }

                    } else {
                        this.bodyMessage = "Something went wrong. Please, try again!";
                    }

                });
            }
        },
    }
</script>

<style lang="scss" scoped>

.options {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    .option {
        height: 50px;
        border: 0;
        background: transparent;
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        &.active {
            font-weight: bold;
            border-bottom: 2px solid $buttonColor;
            color: $buttonColor;
        }
    }
}

.billTo {
    text-align: center;
    display: grid;
    row-gap: 10px;
    .text {
        text-transform: uppercase;
        color: #aaa;
    }
}

.companyHeaderGroupHolder {
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    border: 1px solid $borderColor;
    overflow: hidden;
}

.companyHeaderGroup {
    padding: 100px 30px;
    display: grid;
    justify-content: center;
    justify-items: center;
    row-gap: 50px;
    text-align: center;
    .balance {
        display: grid;
        justify-content: center;
        justify-items: center;
        row-gap: 10px;
        text-align: center;
        .subtle {
            text-transform: uppercase;
            color: #aaa;
        }
        .value {
            font-size: 30px;
            font-weight: bold;
        }
    }
}

.messageGroup {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .message {
        max-width: 450px;
        width: 100%;
        border: 1px solid $borderColor;
        padding: 30px;
        border-radius: $borderRadius;
        background: $bgSecondary;
    }
}

.invoicePage {
    
    .passwordWrapper {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        .formGroup {
            max-width: 450px;
            width: 100%;
            display: grid;
            row-gap: 20px;
            border: 1px solid $borderColor;
            padding: 30px;
            border-radius: $borderRadius;
            background: $bgSecondary;
        }
    }
}

.invoiceGroup {
    .invoiceWrapper {
        padding: 20px;
        max-width: 662px;
        width: 100%;
        margin: 0 auto;
        display: grid;
        row-gap: 20px;
    }
}


</style>