<template>
    <div class="weeksList">

        <ModalSimple v-model="showLoadDocuments" confirm="Got it" @confirm="() => { showLoadDocuments = false }" title="Load documents">
            <div v-if="selectedLoad" class="loadDocs">
                <div class="loadDocLink" :key="key" v-for="(doc, key) of selectedLoad.documents">
                    <div class="name textNoWrap">{{ doc.categoryName }}</div>
                    <div class="view"><a :href="apiURL + doc.path" target="_blank" class="default small round">Open</a></div>
                </div>
            </div>
        </ModalSimple>

        <div class="tabs">
            <button class="default small round" :class="{ 'reverse' : key != selectedWeek  }" @click="selectedWeek = key" :key="key" v-for="(item, key) of invoice.loads">{{ getWeekReadableTitle(item.description) }}</button>
        </div>
        

        <div class="loadsByWeek" v-if="invoice.loads && invoice.loads[selectedWeek]">
            <div class="title">
                <span>{{ getWeekReadableTitle(loadsByTab.description) }}</span>
                <span>${{ priceConverter(loadsByTab.quantity.toFixed(2)) }}</span>
            </div>
            <div class="loads">
                <div class="item" :key="load._id" v-for="load of invoice.loads[selectedWeek].loads">
                    
                    <div class="itemTitle">
                        <div class="loadNumber">Load #{{ load.loadID }}</div>
                        <div class="rate">${{ priceConverter(load.rate) }}</div>
                    </div>

                     <div class="originDestination textNoWrap">
                        <div class="col origin textNoWrap">{{ load.origin }}</div>
                        <div class="icon"><i class="fa-solid fa-arrow-right"></i></div>
                        <div class="col destination textNoWrap">{{ load.destination }}</div>
                     </div>

                     <div class="brokerDetails">{{ load.broker }}</div>

                    <div class="info">
                        <div><button class="textNoWrap"><i class="far fa-calendar-alt"></i>{{ load.date }}</button></div>
                        <div><button class="textNoWrap"><i class="far fa-user"></i>{{ load.driver }}</button></div>
                        <div v-if="load.rateConfirmation && load.rateConfirmation.path">
                            <a :href="apiURL + load.rateConfirmation.path" target="_blank"><button class="active textNoWrap" ><i class="far fa-file"></i>Rate Confirmation</button></a>
                        </div>
                        <div v-else-if="load.documents && load.documents.length > 0"><button class="active textNoWrap" @click="showLoadDetails(load)"><i class="far fa-file"></i>Documents</button></div>
                        <div v-else><button class="active textNoWrap"><i class="far fa-file"></i>No documents</button></div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['invoice'],
        data() {
            return {
                showLoadDocuments: false,
                selectedLoad: null,
                selectedWeek: 0
            }
        },
        computed: {
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            itemsWithLoads() {
                let items =  this.invoice.items.filter(item => {
                    if(item.loads) return true;
                    return false;
                });
                return items;
            },
            loadsByTab() {
                if(!this.itemsWithLoads[this.selectedWeek]) return null;
                let list = JSON.parse(JSON.stringify(this.itemsWithLoads[this.selectedWeek]));
                return list;
            }
        },
        methods: {
            getWeekReadableTitle(week) {
                let date = moment.utc(week, "YYYYWW");
                return 'Week ' + date.isoWeek();
            },
            showLoadDetails(load) {
                this.selectedLoad = load;
                this.showLoadDocuments = true;
            }
        },
    }
</script>

<style lang="scss" scoped>

.tabs {
    display: flex;
    flex-wrap: wrap;
    button {
        margin: 0 10px 10px 0;
    }
}

.brokerDetails {
    padding: 10px;
    text-align: center;
    border-top: 1px solid $borderColor;
}


.loads {
    display: grid;
    row-gap: 10px;
    .item {
        background: $bgSecondary;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        box-shadow: $boxShadow;
        .itemTitle {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px 20px;
            .titleRateBroker {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            .rate {
                font-size: 14px;
                font-weight: 600;
            }
        }
        .info {
            border-top: 1px solid $borderColor;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-template-rows: 50px;
            button {
                width: 100%;
                height: 100%;
                background: transparent;
                border: 0;
                border-right: 1px solid $borderColor;
                cursor: default;
                display: grid;
                grid-template-columns: auto auto;
                text-align: center;
                justify-content: center;
                justify-items: center;
                align-items: center;
                column-gap: 10px;
                &.active {
                    cursor: pointer;
                    &:hover {
                        color: $buttonColor;
                    }
                }
                i {
                    margin-right: 10px;
                }
            }
        }
    }
}


.weeksList {
    display: grid;
    row-gap: 20px;
}

.loadsByWeek {
    .title {
        padding: 15px 0;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
    }
}

.originDestination {
    display: grid;
    row-gap: 10px;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    padding: 20px;
    column-gap: 10px;
    // background: $bg;
    border-top: 1px solid $borderColor;
    .col {
        text-align: center;
    }
    .origin, .destination {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.loadDocs {
    padding: 20px 0;
    .loadDocLink {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        column-gap: 20px;
        align-items: center;
    }
}

@media screen and (max-width: 520px) {
    .loads {
        .item {
            .info {
                grid-template-columns: minmax(0, 1fr);
                grid-template-rows: auto;
                button {
                    border: 0;
                    border-top: 1px solid $borderColor;
                    height: 34px;
                    &:first-child {
                        border: 0;
                    }
                }
            }
        }
    }
    .originDestination {
        grid-template-columns: minmax(0, 1fr);
        justify-content: center;
        justify-items: center;
    }
}
</style>